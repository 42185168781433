import storeBuilder from '@/store/storeBuilder';
import 'bootstrap';
import Vue from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BootstrapVue, { IconsPlugin } from 'bootstrap-vue';
import { buildGateway } from '@/service/Gateway';

import { AuthorizationPlugin, AuthorizationPluginOptions } from '@/service/Authorization';
import App from './App.vue';

import './registerServiceWorker';
import router from './router';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

const gateway = buildGateway();
Vue.use(gateway);

const authOptions: AuthorizationPluginOptions = {
  siteKey: process.env.VUE_APP_SKETCH_RECAPTCHA_KEY,
};

Vue.use(AuthorizationPlugin, authOptions);

const store = storeBuilder();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

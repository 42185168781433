import _Vue from 'vue';
import Order from '@/models/Order';

export type ProgressHandler = (current: number, total: number) => void

export type AuthorizationKey = string;

export default interface Gateway {
  orderCreate(
      authorizationKey: AuthorizationKey,
      order: Order,
      file: File,
      progressHandler: ProgressHandler,
    )
    : Promise<void>;
  // This next eslint disable is crazy.  One lint rule says it needs a semi.
  // Another says the semi is invalid.  The vue project generator's default settings
  // have an error here so just disabling it.  Maybe a fix will come upstream but I don't have time.
  // eslint-disable-next-line semi
}

class GatewayWeb implements Gateway {
  protected serviceHost: string;

  constructor(serviceHost: string) {
    this.serviceHost = serviceHost;
  }

  public orderCreate(
    authorizationKey: AuthorizationKey,
    order: Order,
    file: File,
    onProgress: ProgressHandler,
  ): Promise<void> {
    // @see https://github.com/axios/axios/blob/master/examples/upload/index.html
    const data = new FormData();
    data.append('order', JSON.stringify(order));
    data.append('file', file);

    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      req.open('POST', this.orderCreateUrl(), true);
      req.setRequestHeader('Authorization', `Recaptcha ${authorizationKey}`);
      req.onload = () => {
        if (req.status === 200) {
          resolve();
        } else {
          console.log('status:', req.status);
          reject(new Error('Error Sending Order'));
        }
      };
      req.upload.onprogress = (oEvent) => {
        console.log('progress:', oEvent.loaded, oEvent.total);
        onProgress(oEvent.loaded, oEvent.total);
      };
      req.onerror = (event) => {
        console.log('error sending order:', event);
        reject(new Error('Order could not be sent to service.'));
      };

      req.send(data);
    });
  }

  protected orderCreateUrl(): string {
    return `${this.serviceHost}/order-create`;
  }
}

export type GatewayPlugin = (Vue: typeof _Vue) => void;

export function buildGateway(): GatewayPlugin {
  const serviceHost: string = process.env.VUE_APP_SERVICE_HOST ?? 'http://localhost:8880';
  const gateway = new GatewayWeb(serviceHost);
  return (Vue: typeof _Vue) => {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$gateway = gateway;
  };
}

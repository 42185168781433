import { render, staticRenderFns } from "./SubmissionForm.vue?vue&type=template&id=71aacf21&scoped=true&"
import script from "./SubmissionForm.vue?vue&type=script&lang=ts&"
export * from "./SubmissionForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71aacf21",
  null
  
)

export default component.exports
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import PrintSubmission from '../views/PrintSubmission.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'printsubmission',
    component: PrintSubmission,
  },
  {
    path: '/policy/terms-of-use',
    component: () => import(/* webpackChunkName: "policytermsofuse" */ '../views/PolicyTermsOfUse.vue'),
  },
  {
    path: '/policy/acceptable-use',
    component: () => import(/* webpackChunkName: "policyacceptableuse" */ '../views/PolicyAcceptableUse.vue'),
  },
  {
    path: '/policy/privacy',
    component: () => import(/* webpackChunkName: "policyprivacy" */ '../views/PolicyPrivacy.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

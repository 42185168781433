import _Vue from 'vue';
import { load, ReCaptchaInstance } from 'recaptcha-v3';

interface AuthorizationPluginOptions {
  siteKey: string;
}
export type AuthorizationToken = string;

export type Action = 'CreateOrder';

export interface AuthorizationGateway {
  executeAction: (action: Action) => Promise<AuthorizationToken>;
}

type QueuedActionHandler = (instance: ReCaptchaInstance) => Promise<AuthorizationToken>;

class AuthorizationGatewayRecaptcha implements AuthorizationGateway {
  protected connectedInstance?: ReCaptchaInstance;

  protected loaded: boolean;

  protected queuedActions: Array<QueuedActionHandler>;

  constructor(options: AuthorizationPluginOptions) {
    this.loaded = false;
    this.queuedActions = [];
    console.log('loading...');
    load(options.siteKey).then((recaptcha) => {
      console.log('loaded:', recaptcha);
      // run through the queued ones
      this.queuedActions.forEach((queuedAction) => {
        queuedAction(recaptcha);
      });
      // Now set the instance so they're no longer queued
      this.connectedInstance = recaptcha;
    });
  }

  public executeAction(action: Action): Promise<AuthorizationToken> {
    return new Promise((resolve, reject) => {
      if (this.connectedInstance) {
        console.log('executing:');
        this.connectedInstance.execute(action)
          .then((token: AuthorizationToken) => {
            console.log('executed:', token);
            resolve(token);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        console.log('no connected instance:', this.connectedInstance);
        // Since we don't have a connection yet,
        // Push a promise to eventually complete this, once we're connected.
        this.queuedActions.push((instance) => new Promise((actionResolver, actionRejecter) => {
            instance?.execute(action)
              .then((token: AuthorizationToken) => {
                actionResolver(token);
              })
              .catch((err) => {
                actionRejecter(err);
              });
        }));
      }
    });
  }
}

// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => AuthorizationToken;
export function AuthorizationPlugin(Vue: typeof _Vue, options: AuthorizationPluginOptions): void {
  // Need to do this because it's how vue plugins work.
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$authorization = new AuthorizationGatewayRecaptcha(options);
}

export {
  AuthorizationPluginOptions,
};

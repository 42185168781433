







import Vue from 'vue';
import Component from 'vue-class-component';
import ContactForm from '@/components/SubmissionForm.vue';

@Component({
  components: {
    ContactForm,
  },
})
export default class PrintSubmission extends Vue {

}

































import Vue from 'vue';
import Component from 'vue-class-component';

interface MenuItem {
  to: string;
  label: string;
}

@Component({
  components: {},
})
export default class App extends Vue {
  year: string;

  constructor() {
    super();
    this.year = new Date().getFullYear().toString();
  }
}

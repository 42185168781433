import Vue from 'vue';
import Vuex, { Store } from 'vuex';

Vue.use(Vuex);

interface RootState {
  loggedIn: boolean;
}

const storeBuilder = (): Store<RootState> => {
  const defaultState: RootState = {
    loggedIn: false,
  };

  return new Vuex.Store({
    state: defaultState,
    mutations: {
    },
    getters: {

    },
    actions: {
    },
    modules: {
    },
  });
};

export default storeBuilder;

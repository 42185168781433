

























































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import Order, { ContactMethod } from '@/models/Order';

interface ContactOption {
  value: ContactMethod;
  text: string;
}

type ContactOptions = Array<ContactOption>;

type FormState = 'user_input'|'submitting'|'done';

interface Modal {
  show: () => void;
}

@Component
export default class ContactForm extends Vue {
  selectedContact = 'email';

  preferredContactMethod: ContactMethod;

  phoneNumber: string;

  email: string;

  lastName: string;

  firstName: string;

  referral: string;

  file: File|null = null;

  details: string;

  formState: FormState;

  submitError: string;

  submitCurrent:number;

  submitTotal: number;

  contactOptions: ContactOptions;

  constructor() {
    super();
    this.selectedContact = 'email';
    this.file = null;
    this.formState = 'user_input';
    this.submitError = '';
    this.submitCurrent = 0;
    this.submitTotal = 100;
    this.contactOptions = [
      { value: 'email', text: 'Please e-mail me.' },
      { value: 'call', text: 'Please call my phone number.' },
      { value: 'text', text: 'Please text my phone number.' },
    ];
    this.preferredContactMethod = 'email';
    this.phoneNumber = '';
    this.email = '';
    this.lastName = '';
    this.firstName = '';
    this.referral = '';
    this.details = '';
    this.setDefault();
  }

  protected setDefault(): void {
    // Set the properties pertaining to individual jobs back to default.
    // Leave contact information and other details in place so a user can easily submit
    // multiple jobs.
    this.file = null;
    this.formState = 'user_input';
    this.submitError = '';
    this.submitCurrent = 0;
    this.submitTotal = 100;
    this.contactOptions = [
      { value: 'email', text: 'Please e-mail me.' },
      { value: 'call', text: 'Please call my phone number.' },
      { value: 'text', text: 'Please text my phone number.' },
    ];
    this.details = '';
  }

  async onSubmit(): Promise<void> {
    if (this.file === null) {
      return;
    }
    this.formState = 'submitting';
    const order: Order = {
      preferredContactMethod: this.preferredContactMethod,
      phoneNumber: this.phoneNumber,
      email: this.email,
      lastName: this.lastName,
      firstName: this.firstName,
      referral: this.referral,
      details: this.details,
    };
    try {
      console.log('getting auth key:');
      const key = await this.$authorization.executeAction('CreateOrder');
      console.log('creating order:', key);
      await this.$gateway.orderCreate(key, order, this.file, (current: number, total: number) => {
        this.submitCurrent = current;
        this.submitTotal = total;
      });
      this.formState = 'done';
    } catch (e) {
      console.log('Got Error:', e);
      this.formState = 'user_input';
      this.submitError = e.message;
      this.$bvModal.show('submission-form--error-modal');
    }
  }

  onRefresh(): void {
    this.setDefault();
  }
}
